<template>
  <div class="space-y-4">
    <article v-if="canPlaceOrder || canReceiveOrder">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          v-if="canPlaceOrder"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Order',
            })
          "
          icon="calendar-next"
          text="Create Order"
        >
        </asom-button>
        <asom-button
          v-if="canReceiveOrder"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Move In Items',
            })
          "
          icon="enter"
          text="Move In Item"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Orders">
      <template #header>
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <div class="flex flex-wrap content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Order List
            </h3>
          </div>
          <div class="flex flex-row-reverse">
            <asom-export-to-excel
              fileName="Order List"
              :fields="columns.fields"
              :labels="columns.labels"
              :data="columns.data"
            />
          </div>
        </div>
      </template>
      <div class="grid md:grid-cols-2 lg:gap-x-8 md:gap-x-6 gap-4">
        <asom-form-field label="Order Status">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.orderStatuses"
            :options="orderStatusOptions"
          />
        </asom-form-field>
        <asom-form-field label="Items">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.items"
            :options="inventoryTypeOptions"
            @search-change="queryInventoryTypes"
            placeholder="Select Items"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'inventoryOrderNo',
          'receivedByPersonNo',
          'createdByPersonNo',
          'orderDate',
          'expectedDeliveryDate',
          'deliveryDate',
          'elapsedDays',
          'createdBy',
          'receivedBy',
          'status',
        ]"
        :sortableColumns="[
          'inventoryOrderNo',
          'receivedByPersonNo',
          'createdByPersonNo',
          'orderDate',
          'expectedDeliveryDate',
          'deliveryDate',
          'elapsedDays',
          'createdBy',
          'receivedBy',
          'status',
        ]"
        :data="orderList"
      >
        <template v-if="canViewOrder" v-slot:inventoryOrderNo="scopedSlots">
          <button
            @click="navigateToView(scopedSlots.rowData)"
            class="text-button underline font-semibold"
          >
            {{ scopedSlots.data }}
          </button>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <order-slide-menu v-model="showRightMenu" :selected-order="selectedOrder" />
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters, mapState } from "vuex";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getListOfInventoryTypes } from "../../../services/inventory.service";
import { OrderStatus } from "../../../constants/APIEnums/inventoryEnums";
import OrderSlideMenu from "./OrderSlideMenu";
import { setStorage, getStorage } from "@/helpers/sessionStorage";
import moment from "moment";

export default {
  name: "OrderMainPage",
  components: {
    OrderSlideMenu,
  },
  data() {
    return {
      filters: {
        orderStatuses: [
          OrderStatus.PENDING_DELIVERY.VALUE,
          OrderStatus.IN_REVIEW.VALUE,
        ],
        items: [],
      },
      allOrderStatuses: OrderStatus,
      inventoryTypeOptions: [],
      showRightMenu: false,
      selectedOrder: null,
      orderList: [],
    };
  },
  computed: {
    ...mapState({
      error: (state) => get(state, "inventoryManagement.order.error", null),
      isLoading: (state) =>
        get(state, "inventoryManagement.order.isLoading", false),
      _orderList: (state) => get(state, "inventoryManagement.order.list", []),
    }),
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      canPlaceOrder: "auth/canPlaceInventoryOrder",
      canViewOrder: "auth/canViewInventoryOrder",
      canReceiveOrder: "auth/canReceiveInventoryOrder",
    }),
    orderStatusOptions() {
      return [
        {
          label: OrderStatus.IN_REVIEW.NAME,
          value: OrderStatus.IN_REVIEW.VALUE,
        },
        {
          label: OrderStatus.REJECTED.NAME,
          value: OrderStatus.REJECTED.VALUE,
        },
        {
          label: OrderStatus.PENDING_DELIVERY.NAME,
          value: OrderStatus.PENDING_DELIVERY.VALUE,
        },
        {
          label: OrderStatus.DELIVERY_FULFILLED.NAME,
          value: OrderStatus.DELIVERY_FULFILLED.VALUE,
        },
      ];
    },
    columns() {
      const fields = [
        "inventoryOrderNo",
        "orderDate",
        "expectedDeliveryDate",
        "deliveryDate",
        "elapsedDays",
        "createdBy",
        "receivedBy",
        "status",
      ];
      const labels = [
        "Inventory Order No",
        "Order Date",
        "Expected Delivery Date",
        "Delivery Date",
        "Elapsed Days",
        "Created By",
        "Received By",
        "Status",
      ];
      const data = [];
      this._orderList.forEach((element, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = element[field];
          if (
            field == "orderDate" ||
            field == "expectedDeliveryDate" ||
            field == "deliveryDate"
          ) {
            d = displayUtcDate(d);
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
  },
  watch: {
    showRightMenu: function(newValue) {
      if (!newValue) {
        this.getPageData();
        this.selectedOrder = null;
      }
    },
    stationId() {
      this.getPageData();
    },
    '_orderList': function(){
      this.orderList = this._orderList;
    }
  },
  mounted() {
    if (this.stationId !== null) {
      const searchVal = getStorage("order-list");
      if(searchVal) {
        // UTC时间转换成对应的本地时间
        if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
        if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
        this.filters = searchVal;
      }
      this.$nextTick(() => {
        this.getPageData();
      })
    }
    this.orderList = this._orderList;
  },
  methods: {
    get,
    displayUtcDate,
    resetFilters() {
      this.filters.orderStatuses = [];
      this.filters.items = [];
    },
    async queryInventoryTypes(query) {
      this.modalError = null;
      this.isLoadingInventoryTypes = true;
      if (!query) {
        this.isLoadingInventoryTypes = false;
        this.inventoryTypeOptions = [];
      }
      const result = await getListOfInventoryTypes({
        stationId: this.stationId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.inventoryTypes = get(result, "payload.list", []);
        this.isLoadingInventoryTypes = false;
        this.modalError = null;
        this.inventoryTypeOptions = get(result, "payload.list").map(
          ({ inventoryTypeId, inventoryTypeName }) => ({
            label: inventoryTypeName,
            value: inventoryTypeId,
          })
        );
      } else {
        this.isLoadingInventoryTypes = false;
        this.modalError = result.payload;
        this.inventoryTypeOptions = [];
      }
    },
    async getPageData(status) {
      if(status) setStorage("order-list", this.filters);
      const resp = await this.$store.dispatch(
        "inventoryManagement/order/getOrderList",
        {
          stationId: this.stationId,
          lineId: this.lineId,
          statuses: this.filters.orderStatuses,
          inventoryTypeIds: this.filters.items,
          skip: 0,
          take: 100,
        }
      );
      if (!resp.success) {
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedOrder) {
      if (selectedOrder) {
        this.selectedOrder = selectedOrder;
        this.showRightMenu = true;
      }
    },
  },
};
</script>
